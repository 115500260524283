var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "rtb-card",
    { staticClass: "system-page", attrs: { "ma-4": "", "d-flex": "" } },
    [
      _c("h1", { attrs: { align: "center" } }, [_vm._v("Quote Tool")]),
      _c(
        "rtb-card-body",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-layout",
            {
              staticStyle: { "margin-left": "100px" },
              attrs: { row: "", wrap: "" },
            },
            [
              _c(
                "v-flex",
                { attrs: { xs2: "", "ma-2": "" } },
                [
                  _c("weve-select-field", {
                    attrs: {
                      label: "Events per year",
                      options: _vm.numOfEventsData,
                    },
                    model: {
                      value: _vm.numOfEvents,
                      callback: function ($$v) {
                        _vm.numOfEvents = $$v
                      },
                      expression: "numOfEvents",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs2: "", "ma-2": "" } },
                [
                  _c(
                    "weve-button",
                    {
                      staticStyle: { "margin-top": "25px" },
                      attrs: { size: "sm" },
                      on: { click: _vm.clear },
                    },
                    [_vm._v("Reset")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.numOfEvents, function (numOfEvents, i) {
            return _c(
              "v-layout",
              {
                key: i,
                staticStyle: { "margin-left": "100px" },
                attrs: { row: "", wrap: "" },
              },
              [
                _c(
                  "v-flex",
                  { attrs: { xs2: "", "ma-2": "" } },
                  [
                    _c("weve-select-field", {
                      attrs: {
                        label: _vm.numOfPlayersLabel(i),
                        options: _vm.numOfPlayersData,
                      },
                      model: {
                        value: _vm.numOfPlayers[i],
                        callback: function ($$v) {
                          _vm.$set(_vm.numOfPlayers, i, $$v)
                        },
                        expression: "numOfPlayers[i]",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs2: "", "ma-2": "" } },
                  [
                    _c("weve-select-field", {
                      attrs: {
                        label: "Event Type",
                        options: _vm.eventTypeData,
                      },
                      model: {
                        value: _vm.eventType[i],
                        callback: function ($$v) {
                          _vm.$set(_vm.eventType, i, $$v)
                        },
                        expression: "eventType[i]",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs2: "", "ma-2": "" } },
                  [
                    _c("weve-select-field", {
                      attrs: { label: "Host Type", options: _vm.hostTypeData },
                      model: {
                        value: _vm.hostType[i],
                        callback: function ($$v) {
                          _vm.$set(_vm.hostType, i, $$v)
                        },
                        expression: "hostType[i]",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs2: "", "ma-2": "" } },
                  [
                    _c("weve-select-field", {
                      attrs: {
                        label: _vm.customizationLabel(i),
                        options: _vm.customizationData,
                      },
                      model: {
                        value: _vm.customization[i],
                        callback: function ($$v) {
                          _vm.$set(_vm.customization, i, $$v)
                        },
                        expression: "customization[i]",
                      },
                    }),
                  ],
                  1
                ),
                _vm.hostType[i] == "On-Site"
                  ? _c(
                      "v-flex",
                      { attrs: { xs2: "", "ma-2": "" } },
                      [
                        _c("weve-select-field", {
                          attrs: {
                            label: _vm.zoneLabel(i),
                            options: _vm.zoneData,
                          },
                          model: {
                            value: _vm.zone[i],
                            callback: function ($$v) {
                              _vm.$set(_vm.zone, i, $$v)
                            },
                            expression: "zone[i]",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c("v-layout", { staticClass: "details", attrs: { row: "", wrap: "" } }, [
        _c("table", { staticStyle: { "margin-top": "25px" } }, [
          _c("tr", [
            _c("td", { attrs: { width: "300" } }, [_vm._v("Credits:")]),
            _c("td", [
              _c("b", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.priceAndCredits(
                        _vm.eventType[0],
                        _vm.hostType[0],
                        _vm.zone[0],
                        _vm.numOfPlayers[0],
                        _vm.customization[0]
                      ).credits
                    )
                ),
              ]),
            ]),
          ]),
          _c("tr", [
            _c("td", [_vm._v("Price:")]),
            _c("td", [
              _c("b", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.priceAndCredits(
                        _vm.eventType[0],
                        _vm.hostType[0],
                        _vm.zone[0],
                        _vm.numOfPlayers[0],
                        _vm.customization[0]
                      ).price
                    )
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }