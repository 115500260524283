<template>
  <rtb-card ma-4 d-flex class="system-page">
    <h1 align="center">Quote Tool</h1>
    <rtb-card-body wrap>
      <v-layout row wrap style="margin-left: 100px">
        <v-flex xs2 ma-2>
          <weve-select-field
            label="Events per year"
            v-model="numOfEvents"
            :options="numOfEventsData"
          />
        </v-flex>
        <v-flex xs2 ma-2>
          <weve-button style="margin-top: 25px" size="sm" @click="clear"
            >Reset</weve-button
          >
        </v-flex>
        <!-- <v-flex ma-2 mt-5>
        *One free gold event for every 4 events booked
        </v-flex> -->
      </v-layout>
      <v-layout
        row
        wrap
        style="margin-left: 100px"
        v-for="(numOfEvents, i) in numOfEvents"
        :key="i"
      >
        <v-flex xs2 ma-2>
          <weve-select-field
            :label="numOfPlayersLabel(i)"
            v-model="numOfPlayers[i]"
            :options="numOfPlayersData"
          />
        </v-flex>
        <v-flex xs2 ma-2>
          <weve-select-field
            label="Event Type"
            v-model="eventType[i]"
            :options="eventTypeData"
          />
        </v-flex>
        <v-flex xs2 ma-2>
          <weve-select-field
            label="Host Type"
            v-model="hostType[i]"
            :options="hostTypeData"
          />
        </v-flex>

        <v-flex xs2 ma-2>
          <weve-select-field
            :label="customizationLabel(i)"
            v-model="customization[i]"
            :options="customizationData"
          />
        </v-flex>
        <v-flex xs2 ma-2 v-if="hostType[i] == 'On-Site'">
          <weve-select-field
            :label="zoneLabel(i)"
            v-model="zone[i]"
            :options="zoneData"
          />
        </v-flex>
      </v-layout>
    </rtb-card-body>
    <v-layout row wrap class="details">
      <table style="margin-top: 25px">
        <tr>
          <td width="300">Credits:</td>
          <td>
            <b>
              {{
                priceAndCredits(
                  eventType[0],
                  hostType[0],
                  zone[0],
                  numOfPlayers[0],
                  customization[0]
                ).credits
              }}</b
            >
          </td>
        </tr>
        <tr>
          <td>Price:</td>
          <td>
            <b>
              {{
                priceAndCredits(
                  eventType[0],
                  hostType[0],
                  zone[0],
                  numOfPlayers[0],
                  customization[0]
                ).price
              }}</b
            >
          </td>
        </tr>
      </table>
    </v-layout>
  </rtb-card>
</template>

<script>
import {
  WeveTextField,
  WeveButton,
  WeveSpinbutton,
  WeveToggle,
  WeveSelectField,
  WeveSlider,
  WeveForm
} from "@weve/ui"
import { RtbCard, RtbCardBody } from "@/components/ui"
import useSystem from "@/use/useSystem"

export default {
  name: "FinnPricingCode",
  components: {
    WeveTextField,
    WeveButton,
    WeveTextField,
    WeveSpinbutton,
    WeveToggle,
    WeveSlider,
    WeveForm,
    WeveSelectField,
    RtbCard,
    RtbCardBody
  },
  data() {
    return {
      numOfPlayerLevels: 12,
      eventType: [
        "In Person",
        "Hybrid",
        "IRL",
        "Virtual",
        "Virtual",
        "Virtual",
        "Virtual",
        "Virtual",
        "Virtual",
        "Virtual",
        "Virtual",
        "Virtual",
        "Virtual",
        "Virtual"
      ],
      c1: 499,
      c2: 450,
      c3: 400,
      c6: 380,
      c11: 350,
      c16: 325,
      c21: 300,
      c26: 290,
      c31: 275,
      c50: 250,
      c75: 225,
      c100: 210,
      zoneData: [1, 2, 3, 4],
      zone: [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      hostType: [
        "On-Site",
        "On-Screen",
        "On-Site",
        "Self",
        "On-Screen",
        "On-Site",
        "Self",
        "On-Screen",
        "On-Site",
        "Self",
        "On-Screen",
        "On-Site"
      ],
      hostTypeData: ["Self", "On-Screen", "On-Site"],
      eventTypeData: ["Virtual", "Hybrid", "In Person"],
      numOfPlayersData: [
        10, 25, 50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300
      ],
      proUpgrades: [],
      numOfPlayers: [
        25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25
      ],
      cadenceData: ["Quarterly", "Monthly"],
      cadence: "Quarterly",
      inPersonHosts: 0,
      zone1HostCredits: 4,
      zone2HostCredits: 7,
      zone3HostCredits: 10,
      zone4HostCredits: 15,
      premiumCredits: 5,
      proCredits: 1,
      inPersonHostsData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      IRLEvents: 1,
      IRLEventsData: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 24, 36, 50, 75, 100
      ],
      virtualEvents: 3,
      virtualEventsData: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 24, 36, 50, 75, 100
      ],
      priceMatrix: [{ 1: 500, 2: 350 }],
      customizationData: ["Standard", "Pro", "Premium"],

      numOfEvents: 1,
      numOfEventsData: [1],
      virtualCredits: 0,
      IRLCredits: 0,
      discountPercent: 0,
      discountPercentData: [0, 5, 10, 15],
      goldUpgrade: [
        "No",
        "No",
        "No",
        "No",
        "No",
        "No",
        "No",
        "No",
        "No",
        "No",
        "No",
        "No"
      ],
      customization: [
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard",
        "Standard"
      ],
      goldUpgradeCredits: 3,
      goldUpgradeData: ["No", "Yes"],
      onSiteHost: [
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen",
        "On Screen"
      ],
      onSiteHostData: [
        "On Screen",
        "On Site Zone 1",
        "On Site Zone 2",
        "On Site Zone 3",
        "On Site Zone 4"
      ]
    }
  },
  methods: {
    priceAndCredits(
      format,
      hostType,
      zone,
      numOfPlayers,
      customization,
      experienceCreditBump
    ) {
      var priceType = null
      var zone1HostCredits = 4
      var zone2HostCredits = 7
      var zone3HostCredits = 10
      var zone4HostCredits = 15
      var premiumCredits = 4
      var proCredits = 1
      console.log("INSIDE")
      console.log("format", format)
      // translates format and host type into price format
      if (format == "Virtual" && hostType == "Self")
        return { price: "we don't offer virtually self hosted", credits: 0 }
      if (format == "Virtual" && hostType == "On-Screen") priceType = "Virtual"
      if (format == "Virtual" && hostType == "On-Site")
        return {
          price: "If its virtual, you can't have an On-Site host",
          credits: 0
        }
      if (format == "Hybrid" && hostType == "Self")
        return { price: "We don't offer hybrid self hosted events", credits: 0 }
      if (format == "In Person" && hostType == "Self") priceType = "Self-Hosted"
      if (
        (format == "In Person" || format == "Hybrid") &&
        hostType == "On-Screen"
      )
        priceType = "IRL / Hybrid On Screen"
      if (
        (format == "In Person" || format == "Hybrid") &&
        hostType == "On-Site"
      ) {
        console.log("ZOne", zone)
        if (zone == 1) {
          priceType = "On-Site Zone 1"
        } else if (zone == 2) {
          priceType = "On-Site Zone 2"
        } else if (zone == 3) {
          priceType = "On-Site Zone 3"
        } else if (zone == 4) {
          priceType = "On-Site Zone 4"
        }
      }
      // calculate credits

      var c = 0
      if (priceType == "Virtual") c = numOfPlayers / 25
      if (priceType == "IRL / Hybrid On Screen") c = numOfPlayers / 25 + 1
      if (priceType == "On-Site Zone 1")
        c = (numOfPlayers / 25) * 2 + zone1HostCredits
      if (priceType == "On-Site Zone 2")
        c = (numOfPlayers / 25) * 2 + zone2HostCredits
      if (priceType == "On-Site Zone 3")
        c = (numOfPlayers / 25) * 2 + zone3HostCredits
      if (priceType == "On-Site Zone 4")
        c = (numOfPlayers / 25) * 2 + zone4HostCredits

      console.log("C", c)
      if (customization == "Pro") c = c + proCredits
      if (customization == "Premium") c = c + premiumCredits
      if (experienceCreditBump > 0) c = c + experienceCreditBump

      // calculate price and the self-hosted exception

      var p = this.pricePerCredit(c) * c
      if (priceType == "Self-Hosted") {
        if (numOfPlayers == 10) {
          c = 1
          p = 399
        }
        if (numOfPlayers == 25) {
          c = 1
          p = 499
        }
        if (numOfPlayers == 50) {
          c = 2
          p = 699
        }
        if (numOfPlayers == 75) {
          c = 2
          p = 850
        }
        if (numOfPlayers == 100) {
          c = 2
          p = 900
        }
        if (numOfPlayers == 125) {
          c = 2
          p = 900
        }
        if (numOfPlayers == 150) {
          c = 3
          p = 1350
        }
        if (numOfPlayers == 175) {
          c = 4
          p = 1600
        }
        if (numOfPlayers == 200) {
          c = 5
          p = 2000
        }
        if (numOfPlayers == 225) {
          c = 5
          p = 2000
        }
        if (numOfPlayers == 250) {
          c = 6
          p = 2400
        }
        if (numOfPlayers == 275) {
          c = 6
          p = 2400
        }
        if (numOfPlayers == 300) {
          c = 6
          p = 2400
        }
      }
      // calculate the 10 player exception

      if (numOfPlayers == 10) {
        if (priceType == "Self-Hosted") {
          c = 1
          p = 399
        }
        if (priceType == "Virtual") {
          c = 1
          p = 399
        }
        if (priceType == "IRL / Hybrid On Screen") {
          c = 1
          p = 599
        }
        if (priceType == "On-Site Zone 1") {
          c = 2 + zone1HostCredits
          p = this.pricePerCredit(c) * c
        }
        if (priceType == "On-Site Zone 2") {
          c = 2 + zone2HostCredits
          p = this.pricePerCredit(c) * c
        }
        if (priceType == "On-Site Zone 3") {
          c = 2 + zone3HostCredits
          p = this.pricePerCredit(c) * c
        }
        if (priceType == "On-Site Zone 4") {
          c = 2 + zone4HostCredits
          console.log("CREDITS FOR ZONE 4", c)
          p = this.pricePerCredit(c) * c
        }
      }
      var obj = {}
      obj.credits = c
      obj.price = p
      return obj
    },
    clear() {
      location.reload()
    },

    numOfPlayersLabel(i) {
      var b = i + 1
      return "Participants for Event " + b
    },
    customizationLabel(i) {
      var c = 0
      if (this.customization[i] == "Standard") {
        return "Pro / Premium Option"
      } else if (this.customization[i] == "Pro") {
        return "Pro (" + this.proCredits + " credits" + ")"
      } else {
        return "Premium (" + this.premiumCredits + " credits" + ")"
      }
    },
    zoneLabel(i) {
      var c = 0

      if (this.zone[i] == 1) {
        return "Zone " + 1 + " (" + this.zone1HostCredits + " credits)"
      }
      if (this.zone[i] == 2) {
        return "Zone " + 2 + " (" + this.zone2HostCredits + " credits)"
      }
      if (this.zone[i] == 3) {
        return "Zone " + 3 + " (" + this.zone3HostCredits + " credits)"
      }
      if (this.zone[i] == 4) {
        return "Zone " + 4 + " (" + this.zone4HostCredits + " credits)"
      }
      return "Zone"
    },

    pricePerCredit(c) {
      if (c == 1) return this.c1
      if (c == 2) return this.c2
      if (c >= 3 && c < 6) return this.c3
      if (c >= 6 && c < 11) return this.c6
      if (c >= 11 && c < 16) return this.c11
      if (c >= 16 && c < 21) return this.c16
      if (c >= 21 && c < 26) return this.c21
      if (c >= 26 && c < 31) return this.c26
      if (c >= 31 && c < 50) return this.c31
      if (c >= 50 && c < 100) return this.c50
      if (c >= 100) return this.c100
    }
  },
  computed: {
    i() {
      return this.numOfPlayersData.indexOf(this.numOfPlayers)
    }
  }
}
</script>
<style>
.system-page {
  color: white;
  background-color: black;
}
.details {
  background-color: black;
  width: 60%;
  font-size: 24px;
  margin-right: auto;
  margin-left: auto;
}
.vs__selected {
  color: #fff !important;
}
</style>
